import axios from 'axios';
import mutations from './mutations';
import apiLinks from '@/store/api.js';

const state = () => ({
  data: [],
  dataUser: {},
  userAccounts: [],
  pagination: [],
  userAudits: [],
  message: {},
  loading: true,
  loadingAudits: true,
  error: null,
  paginationAudits: [],
});

const actions = {
  async getAllUsers({ commit }, { queryLink }) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(`${apiLinks.users}${queryLink}`);
      await commit('getAllUsersSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
  async createUser({ commit }, user) {
    try {
      const query = await axios.post(`${apiLinks.users}`, user);
      await commit('createUserSuccess', query.data);
    } catch (err) {
      await commit('createUserError', err);
    }
  },
  async getUserAudits({ commit }, { queryLink, user_id }) {
    try {
      commit('getUserAuditsBegin');
      const query = await axios.get(`${apiLinks.audits}${queryLink}&owner_id=${user_id}`);
      await commit('getUserAuditsSuccess', query.data);
    } catch (err) {
      await commit('getUserAuditsError', err);
    }
  },
  async deleteUser({ commit }, id) {
    try {
      await axios.delete(`${apiLinks.users}${id}/`);
      commit('deleteUserSuccess', id);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
  async getDataUser({ commit }, id) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(`${apiLinks.users}${id}/`);
      await commit('getDataUserSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
  async disableTwoFac({ commit }, id) {
    try {
      const query = await axios.patch(`${apiLinks.users}${id}/${apiLinks.disable_otp}`);
      await commit('getDataUserSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
  async getUserAccount({ commit }, id) {
    try {
      const query = await axios.get(`${apiLinks.users}${id}/accounts`);
      await commit('getUserAccountSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
  async changeUserPassword({ commit }, { id, password, password_confirmation }) {
    try {
      commit('axiosChangeBegin');
      await axios.patch(`${apiLinks.users}${id}/${apiLinks.password}`, {
        password,
        password_confirmation,
      });
      commit('axiosChangeSuccess');
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
  async changeUserPhone({ commit }, { id, phone }) {
    try {
      commit('axiosChangeBegin');
      await axios.patch(`${apiLinks.users}${id}/`, {
        phone,
      });
      commit('changeUserPhoneSuccess', phone);
    } catch (err) {
      commit('axiosReadErr', err);
    }
  },

  async changeUserComment({ commit }, { id, comment }) {
    try {
      commit('axiosChangeBegin');
      await axios.patch(`${apiLinks.users}${id}/`, {
        comment,
      });
      commit('changeUserCommentSuccess', comment);
    } catch (err) {
      commit('axiosReadErr', err);
    }
  },

  async changeUserRole({ commit }, { id, role_id }) {
    try {
      commit('axiosChangeBegin');
      const query = await axios.patch(`${apiLinks.users}${id}/`, {
        role_id,
      });
      query.data.role.id = role_id;
      commit('changeUserRoleSuccess', query.data.role);
    } catch (err) {
      commit('axiosReadErr', err);
    }
  },

  async changeUserData({ commit }, { id, data }) {
    const { first_name, middle_name, last_name, date_birth, country, locale } = data;
    try {
      commit('axiosChangeBegin');
      axios.patch(`${apiLinks.users}${id}/`, { locale });
      const newData = await axios.patch(`${apiLinks.users}${id}/${apiLinks.personal_data}`, {
        first_name,
        middle_name,
        last_name,
        date_birth,
        country,
      });
      data.date_birth = newData.data.date_birth;
      commit('changeUserDataSuccess', data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
  async changeUserEmail({ commit }, { id, email }) {
    try {
      await axios.patch(`${apiLinks.users}${id}/${apiLinks.email}`, {
        email,
      });
      commit('changeEmailSuccess', email);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },

  clearUserData({ commit }) {
    commit('clearData');
  },
  clearErrors({ commit }) {
    commit('axiosChangeBegin');
  },

  async changeReferral({ commit }, { referral, user_id }) {
    try {
      commit('axiosChangeBegin');
      const query = await axios.patch(`${apiLinks.users}${user_id}/${apiLinks.parent}?ref_code=${referral}`);
      commit('axiosChangeReferralSuccess', query.data);
    } catch (err) {
      commit('axiosGetErr', err);
    }
  },
  async changeLevel({ commit }, { level, user_id }) {
    try {
      commit('axiosChangeBegin');
      const query = await axios.patch(`${apiLinks.users}${user_id}/${apiLinks.level}?level=${level}`);
      commit('axiosChangeReferralSuccess', query.data);
    } catch (err) {
      commit('axiosGetErr', err);
    }
  },
  async changeSubKind({ commit }, { kind, user_id }) {
    try {
      commit('axiosChangeBegin');
      const query = await axios.patch(`${apiLinks.users}${user_id}/${apiLinks.subscription}?sub_kind=${kind}`);
      commit('axiosChangeReferralSuccess', query.data);
    } catch (err) {
      commit('axiosGetErr', err);
    }
  },
  async addSub({ commit }, { count, user_id }) {
    try {
      commit('axiosChangeBegin');
      const query = await axios.post(`${apiLinks.users}${user_id}/${apiLinks.subscription}?count=${count}`);
      commit('axiosChangeReferralSuccess', query.data);
    } catch (err) {
      commit('axiosGetErr', err);
    }
  },
  async createPromoInvest({ commit }, { account_id, user_id, amount }) {
    try {
      commit('axiosChangeBegin');
      const query = await axios.post(
        `${apiLinks.investor_accounts}?published_account_id=${account_id}&amount=${amount}&user_id=${user_id}`,
      );
      commit('axiosChangeReferralSuccess', query.data);
    } catch (err) {
      commit('axiosCatchErr', err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
