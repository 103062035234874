export default {
  axiosReadBegin(state) {
    state.loading = true;
  },
  axiosReadErr(state, err) {
    state.error = err;
    state.loading = false;
  },
  getAdminSessionsSuccess(state, data) {
    state.adminSessionsData = data.data;
    state.paginationAdmin = data.meta.pagination;
    state.loading = false;
  },
  getAllSessionsSuccess(state, data) {
    state.allSessionsData = data.data;
    state.paginationAll = data.meta.pagination;
    state.loading = false;
  },
  getSessionSuccess(state, data) {
    state.sessionData = data.data.attributes;
    state.loading = false;
  },
  getAuditSuccess(state, data) {
    state.auditData = data.data.attributes;
    state.loading = false;
  },
  getAllAuditsSuccess(state, data) {
    state.auditsData = data.data;
    state.paginationAudits = data.meta.pagination;
    state.loading = false;
  },
  closeSessionAdminSuccess(state, { adminIdx }) {
    state.adminSessionsData.splice(adminIdx, 1);
  },
  closeSessionUserSuccess(state, { userIdx }) {
    state.allSessionsData[userIdx].attributes.deleted_at = new Date();
  },
  getConfigsSuccess(state, data) {
    state.configsData = data.data;
    state.loading = false;
  },
  sendConfigSuccess(state, data) {
    state.conf = data.data;
  },

  getConfigSuccess(state, data) {
    state.configData = data.data;
    state.loading = false;
  },
};
