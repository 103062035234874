export default [
  {
    path: '/accounts/:deposit?/:accId?',
    name: 'accounts',
    component: () => import('@/view/accounts/AccountsCrud.vue'),
    props: true,
  },
  {
    path: '/account/:id/',
    name: 'Account',
    props: true,
    component: () => import('@/view/accounts/AccountData.vue'),
  },
];
