export default [
  {
    path: '/applications/withdrawals/',
    name: 'withdrawal',
    component: () => import('@/view/applications/WithdrawalsCrud.vue'),
    props: true,
  },
  {
    path: '/applications/deposits/',
    name: 'deposit',
    component: () => import('@/view/applications/DepositsCrud.vue'),
    props: true,
  },
  {
    path: '/applications/transfers/',
    name: 'transfers',
    component: () => import('@/view/applications/TransfersCrud.vue'),
    props: true,
  },
  {
    path: '/applications/exchange-tokens/',
    name: 'exchange-tokens',
    component: () => import('@/view/applications/ExchangeTokensCrud.vue'),
    props: true,
  },
  {
    path: '/applications/transfer/:id',
    name: 'RequestTransfer',
    component: () => import('@/view/applications/TransfersData.vue'),
    props: true,
  },

  {
    path: '/applications/transfer/:id',
    name: 'Transfer',
    props: true,
    redirect: { name: 'RequestTransfer' },
  },
  {
    path: '/applications/deposit/:id',
    name: 'RequestDeposit',
    component: () => import('@/view/applications/DepositData.vue'),
    props: true,
  },
  {
    path: '/applications/exchange-token/:id',
    name: 'exchange-token',
    component: () => import('@/view/applications/ExchangeTokensData.vue'),
    props: true,
  },
  {
    path: '/applications/deposit/:id',
    name: 'Deposit',
    props: true,
    redirect: { name: 'RequestDeposit' },
  },
  {
    path: '/applications/withdrawal/:id',
    name: 'RequestWithdrawal',
    component: () => import('@/view/applications/WithdrawalData.vue'),
    props: true,
  },
  {
    path: '/applications/withdrawal/:id',
    name: 'Withdrawal',
    props: true,
    redirect: { name: 'RequestWithdrawal' },
  },
  {
    path: '/applications/transactions/',
    name: 'transactions',
    component: () => import('@/view/applications/TransactionsCrud.vue'),
    props: true,
  },
  {
    path: '/applications/transaction/:id',
    name: 'RequestTransaction',
    component: () => import('@/view/applications/TransactionsData.vue'),
    props: true,
  },
  {
    path: '/applications/payment-gate/:id?',
    name: 'paymentGate',
    component: () => import('@/view/paymentGates/PaymentGatesData.vue'),
    props: true,
  },
  {
    path: '/applications/payment-gates/',
    name: 'paymentGates',
    component: () => import('@/view/paymentGates/PaymentGatesCrud.vue'),
    props: true,
  },
  {
    path: '/applications/admin-withdrawal/:id?',
    name: 'adminWithdrawal',
    component: () => import('@/view/adminWithdrawals/adminWithdrawalData.vue'),
    props: true,
  },
  {
    path: '/applications/admin-withdrawals/',
    name: 'adminWithdrawals',
    component: () => import('@/view/adminWithdrawals/adminWithdrawalsCrud.vue'),
    props: true,
  },
];
