export default {
  axiosReadBegin(state) {
    state.loading = true;
  },
  changeTableData(state, data) {
    state.tableSettings = data;
  },
  profilePersonalDataSuccess(state, data) {
    state.adminData = data.data.attributes;
    state.loading = false;
  },

  profilePersonalDataErr(state, err) {
    state.error = err.response.data;
    state.loading = false;
  },
  axiosDataChangeSuccess(state, data) {
    state.changeSuccess = data.statusText;
    if (data.statusText === 'OK') {
      state.error = null;
      state.adminData.updated_at = data.data.updated_at;
      state.adminData.user_personal_data = Object.assign(state.adminData.user_personal_data, data.data);
    }
  },
  axiosChangeSettingsSuccess(state, data) {
    state.changeSuccess = data.statusText;
    if (data.statusText === 'OK') {
      state.error = null;
      state.adminSettings.locale = data.data.data.attributes.locale;
      state.adminSettings.time_zone = data.data.data.attributes.time_zone;
    }
  },
  axiosChangeSuccess(state, data) {
    state.error = null;
    state.changeSuccess = data;
  },
  axiosChangeErr(state, data) {
    state.error = data;
  },
  axiosGetSuccess(state, data) {
    state.adminSettings = data.data.attributes;
    state.locale = state.adminSettings.locale;
    state.twoFac = data.data;
    state.loading = false;
  },
  axiosGetErr(state, data) {
    state.error = data;
  },
  axiosEnableTwoFac(state, data) {
    state.twoFactorKey = data.otp;
  },
  axiosDisableTwoFac(state) {
    state.twoFactorKey = 'deletedSecondAuth';
  },
  mailAccepted(state, data) {
    state.mailAcc = data;
  },
};
