export default {
  axiosReadBegin(state) {
    state.loading = true;
  },
  axiosChangeBegin(state) {
    state.error = null;
  },
  axiosChangeSuccess(state) {
    state.error = null;
    state.loading = false;
  },

  getAllUsersSuccess(state, data) {
    state.error = null;
    state.data = data.data;
    state.pagination = data.meta.pagination;
    state.loading = false;
  },
  deleteUserSuccess(state, id) {
    state.data = state.data.filter((val) => val.id !== id);
  },
  getDataUserSuccess(state, data) {
    state.error = null;
    state.dataUser = data.data;
    state.loading = false;
  },

  clearData(state) {
    state.dataUser = {};
  },

  getUserAccountSuccess(state, data) {
    state.error = null;
    state.userAccounts = data.data;
    state.loading = false;
  },

  getUserAuditsBegin(state) {
    state.error = null;
    state.loadingAudits = true;
  },

  getUserAuditsSuccess(state, data) {
    state.error = null;
    state.userAudits = data.data;
    state.paginationAudits = data.meta.pagination;
    state.loadingAudits = false;
  },

  getUserAuditsError(state, err) {
    state.error = err.response.data;
    state.loadingAudits = false;
  },

  createUserSuccess(state, data) {
    state.error = null;
    state.message = data.errors;
  },

  axiosReadErr(state, err) {
    state.error = err.response.data;
    state.loading = false;
  },

  changeEmailSuccess(state, data) {
    state.error = null;
    state.dataUser.attributes.email = data;
  },

  changeUserCommentSuccess(state, comment) {
    state.error = null;
    state.dataUser.attributes.comment = comment;
  },

  changeUserRoleSuccess(state, role) {
    state.error = null;
    state.dataUser.attributes.role = role;
  },
  changeUserPhoneSuccess(state, phone) {
    state.error = null;
    state.dataUser.attributes.phone = '+' + phone;
  },
  changeUserDataSuccess(state, data) {
    state.error = null;
    state.dataUser.attributes.updated_at = Date.now();
    state.dataUser.attributes.locale = data.locale;
    state.dataUser.attributes.user_personal_data = Object.assign(state.dataUser.attributes.user_personal_data, data);
  },
  getUserDepositsSuccess(state, data) {
    state.userDepositsData = data.data;
    state.paginationDeposits = data.meta.pagination;
    state.error = null;
    state.loading = false;
  },

  getUserTransfersSuccess(state, data) {
    state.userTransfersData = data.data;
    state.paginationTransfers = data.meta.pagination;
    state.error = null;
    state.loading = false;
  },

  getUserWithdrawalsSuccess(state, data) {
    state.userWithdrawalsData = data.data;
    state.paginationWithdrawals = data.meta.pagination;
    state.error = null;
    state.loading = false;
  },
  axiosChangeReferralSuccess(state, data) {
    if (data.errors) {
      state.error = data.errors;
    }
  },

  axiosCatchErr(state, data) {
    console.log(data);

    if (data.response.data.errors) {
      state.error = data.response.data.errors;
    } else {
      state.error = data;
    }

    state.isLoading = false;
  },
};
