export default [
  {
    path: '/admin-account/',
    name: 'adminAccount',
    props: true,
    children: [
      {
        path: ':changeType',
        name: 'change',
        component: () => import('@/view/Admin/AdminModalChange.vue'),
        props: true,
      },
    ],
    component: () => import('@/view/Admin/AdminData.vue'),
  },
  {
    path: '/admin/settings/:themeType?',
    name: 'settings',
    component: () => import('@/view/Admin/AdminSettings.vue'),
    props: true,
  },
];
