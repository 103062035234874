export default {
  axiosTradingReadBegin(state) {
    state.loading = true;
    state.error = null;
  },
  axiosTradingReadSuccess(state) {
    state.loading = false;
    state.error = null;
  },
  axiosReadSuccess(state, data) {
    state.data = data.data;
    state.pagination = data.meta.pagination;
    state.loading = false;
  },
  axiosTradingLogsGetSuccess(state, data) {
    state.logs = data.data;
    state.logsPagination = data.meta.pagination;
    state.loading = false;
  },
  axiosTradingOperationsGetSuccess(state, data) {
    state.operations = data.data;
    state.operationsPagination = data.meta.pagination;
    state.loading = false;
  },
  axiosTradingSyncingGetSuccess(state, data) {
    state.syncing = data.data;
    state.syncingPagination = data.meta.pagination;
    state.loading = false;
  },
  axiosAccountTopUp(state, data) {
    state.message = data.errors;
    state.loading = false;
  },
  axiosReadAccSuccess(state, data) {
    state.dataAccount = data.data;
    state.loading = false;
  },
  axiosReadErr(state, err) {
    state.error = err;
    state.loading = false;
  },
  axiosAccountGraphStats(state, data) {
    state.graphData = data;
    state.loading = false;
  },
};
