import apiLinks from '@/store/api.js';

import axios from 'axios';

class AuthService {
  login(user) {
    return axios
      .post(apiLinks.login, {
        email: user.email,
        password: user.password,
      })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  refresh(token) {
    return axios
      .post(apiLinks.refresh, {
        refresh: token,
      })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
      });
  }
  authSecond(user) {
    return axios
      .post(apiLinks.second_auth, {
        email: user.email,
        password: user.password,
        otp_attempt: user.code,
      })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
      });
  }
  logout() {
    const user = JSON.parse(localStorage.getItem('user'));
    const updatableEntity = {
      refresh: user.refresh,
    };
    return axios.post(apiLinks.logout, updatableEntity).then(
      setTimeout(() => {
        localStorage.removeItem('user');
      }),
    );
  }
}

export default new AuthService();
