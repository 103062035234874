import axios from 'axios';
import mutations from './mutations';
import apiLinks from '@/store/api.js';

const state = () => ({
  notifications: null,
  tosterNotifications: null,
  loading: true,
  notification: null,
  pagination: [],
  error: null,
  newNotifications: 0,
  customNotifications: [],
});

const actions = {
  async getAllNotifications({ commit }, { queryLink }) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(`${apiLinks.notifications_url}${queryLink}`);
      commit('getNotificationsSuccess', query.data);
    } catch (err) {
      await commit('axiosSendErr', err);
    }
  },
  async getAllTosterNotifications({ commit }, perPage) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(
        `${apiLinks.notifications_url}?page=1&per_page=${perPage}&sort_by=read_at&order=desc`,
      );
      commit('zeroNewNotifications');
      commit('getTosterNotificationsSuccess', query.data);
      query.data.data.forEach((e) => {
        if (!e.attributes.read_at) commit('changeNewNotifications');
      });
    } catch (err) {
      await commit('axiosSendErr', err);
    }
  },
  getNewDataNotification({ commit }, data) {
    let newData;
    if (data.request_deposit) {
      newData = {
        attributes: {
          created_at: data.request_deposit.created_at,
          message: `Deposit request by ${data.request_deposit.kind} has been ${
            data.request_deposit.status == 'waiting' ? 'created' : data.request_deposit.status
          }`,
          read_at: null,
        },
        id: data.request_deposit.id,
      };
    } else if (data.request_withdrawal) {
      newData = {
        attributes: {
          created_at: data.request_withdrawal.created_at,
          message: `Deposit request ${data.request_withdrawal.id} has been ${data.request_withdrawal.status}`,
          read_at: null,
        },
        id: data.request_withdrawal.id,
      };
    }
    commit('updateNotifications', newData);
  },
  async readNotification({ commit }, id) {
    try {
      axios.post(`${apiLinks.notifications_url}${id}/${apiLinks.read}`);
    } catch (err) {
      await commit('axiosSendErr', err);
    }
  },
  async getNotification({ commit }, id) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(apiLinks.notifications_url + id);
      commit('getNotificationSuccess', query.data);
    } catch (err) {
      await commit('axiosSendErr', err);
    }
  },
  addNotification({ commit }, data) {
    commit('addNotification', data);
  },
  async createCustomNotification({ commit }, data) {
    try {
      //commit("create");
      //const query = await axios.get(
      //  apiLinks.notifications_url + id
      //);
      data.id = Math.floor(Math.random() * 10000);
      data.created_at = Date.now();
      data = JSON.parse(JSON.stringify(data));
      await commit('createCustomNotificationSuccess', data);
    } catch (err) {
      await commit('axiosSendErr', err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
