import axios from 'axios';
import mutations from './mutations';
import apiLinks from '@/store/api.js';

const state = () => ({
  data: {},
  newPartners: new Map([]),
  partners: [],
  pagination: [],
  error: null,
  loading: true,
});
function recursion(parent) {
  let child = [];
  parent.children.map(function (element) {
    element.attributes.level = parent.level + 1;
    let d = element.attributes;
    d.id = element.id;

    child.push({
      key: element.id,
      data: d,
      children: element.attributes.children ? recursion(element.attributes) : null,
    });
  });
  return child;
}

const actions = {
  async axiosPartnersGetData({ commit }, id) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(`${apiLinks.users}${id}/referal_tree`);
      const data = query.data.data.attributes.partners.data;
      let treeData = [];
      data.map((e) => {
        e.attributes.level = 1;
        let d = e.attributes;
        d.id = e.id;
        d.level = 1;

        treeData.push({
          key: e.id,
          data: d,
          children: e.attributes.children ? recursion(e.attributes) : null,
        });
      });
      commit('axiosPartnersReadSuccess', treeData);
    } catch (err) {
      await commit('axiosPartnersReadErr', err);
    }
  },
  clearPartnerData({ commit }) {
    commit('clearPartners');
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
