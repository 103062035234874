export default {
  axiosReadBegin(state) {
    state.loading = true;
  },

  getDepositsSuccess(state, data) {
    state.deposits = data.data;
    state.paginationDeposit = data.meta.pagination;
    state.error = null;
    state.loading = false;
  },

  getDepositSuccess(state, data) {
    state.depositData = data.data;
    state.error = null;
    state.loading = false;
  },

  getTransfersSuccess(state, data) {
    state.transfers = data.data;
    state.paginationTransfers = data.meta.pagination;
    state.transfersStats = data.meta.stats;
    state.error = null;
    state.loading = false;
  },

  getExchangesSuccess(state, data) {
    state.exchanges = data.data;
    state.paginationExchanges = data.meta.pagination;
    state.error = null;
    state.loading = false;
  },

  getTransferSuccess(state, data) {
    state.transferData = data.data;
    state.error = null;
    state.loading = false;
  },

  getExchangeSuccess(state, data) {
    state.exchangeData = data.data;
    state.error = null;
    state.loading = false;
  },

  getTransactionsSuccess(state, data) {
    state.transactions = data.data;
    state.paginationTransactions = data.meta.pagination;
    state.error = null;
    state.loading = false;
  },

  getTransactionSuccess(state, data) {
    state.transactionData = data.data;
    state.error = null;
    state.loading = false;
  },

  rejectDepositSuccess(state, idx) {
    if (+idx === 0 || idx) {
      state.deposits[idx].attributes.status = 'rejected';
      state.deposits[idx].attributes.loading = false;
    } else {
      state.depositData.attributes.status = 'rejected';
      state.depositsData.attributes.loading = false;
    }
    state.error = null;
  },

  approveDepositSuccess(state, idx) {
    if (+idx === 0 || idx) {
      state.deposits[idx].attributes.status = 'approved';
      state.deposits[idx].attributes.loading = false;
    } else {
      state.depositData.attributes.status = 'approved';
      state.depositsData.attributes.loading = false;
    }
    state.error = null;
  },

  rejectTransferSuccess(state, idx) {
    if (+idx === 0 || idx) {
      state.transfers[idx].attributes.status = 'rejected';
      state.transfers[idx].attributes.loading = false;
    } else {
      state.transferData.attributes.status = 'rejected';
      state.transferData.attributes.loading = false;
    }
    state.error = null;
  },

  approveTransferSuccess(state, idx) {
    if (+idx === 0 || idx) {
      state.transfers[idx].attributes.status = 'approved';
      state.transfers[idx].attributes.loading = false;
    } else {
      state.transferData.attributes.status = 'approved';
      state.transferData.attributes.loading = false;
    }
    state.error = null;
  },

  getWithdrawalsSuccess(state, data) {
    state.withdrawals = data.data;
    state.paginationWithdrawal = data.meta.pagination;
    state.error = null;
    state.loading = false;
  },

  getWithdrawalSuccess(state, data) {
    state.withdrawalData = data.data;
    state.error = null;
    state.loading = false;
  },

  rejectWithdrawalSuccess(state, { idx, comment }) {
    if (+idx === 0 || idx) {
      state.withdrawals[idx].attributes.status = 'rejected';
      state.withdrawals[idx].attributes.comment = comment;
      state.withdrawals[idx].attributes.loading = false;
    } else {
      state.withdrawalData.attributes.status = 'rejected';
      state.withdrawalData.attributes.comment = comment;
      state.withdrawalData.attributes.loading = false;
    }
    state.error = null;
  },

  approveWithdrawalSuccess(state, idx) {
    if (+idx === 0 || idx) {
      state.withdrawals[idx].attributes.status = 'approved';
      state.withdrawals[idx].attributes.loading = false;
    } else {
      state.withdrawalData.attributes.status = 'approved';
      state.withdrawalData.attributes.loading = false;
    }
    state.error = null;
  },

  runWithdrawalSuccess(state, idx) {
    if (+idx === 0 || idx) {
      state.withdrawals[idx].attributes.status = 'processing';
      state.withdrawals[idx].attributes.loading = false;
    } else {
      state.withdrawalData.attributes.status = 'processing';
      state.withdrawalData.attributes.loading = false;
    }
    state.error = null;
  },

  getWalletsSuccess(state, data) {
    state.wallets = data.data;
    state.paginationWallets = data.meta.pagination;
    state.error = null;
    state.loading = false;
  },

  getWalletSuccess(state, data) {
    state.walletData = data.data;
    state.error = null;
    state.loading = false;
  },

  rejectWalletSuccess(state, { idx, comment }) {
    if (idx || +idx === 0) {
      state.wallets[idx].attributes.status = 'rejected';
      state.wallets[idx].attributes.comment = comment;
      state.wallets[idx].attributes.loading = false;
    } else {
      state.walletData.attributes.status = 'rejected';
      state.walletData.attributes.comment = comment;
      state.walletData.attributes.loading = false;
    }
    state.error = null;
  },

  approveWalletSuccess(state, idx) {
    if (idx || +idx === 0) {
      state.wallets[idx].attributes.status = 'approved';
    } else {
      state.walletData.attributes.status = 'approved';
    }
    state.error = null;
  },

  axiosReadErr(state, err) {
    state.error = err;
    state.loading = false;
  },

  getPaymentGatesSuccess(state, data) {
    state.paymentGates = data.data;
    state.paginationPaymentGates = data.meta.pagination;
    state.error = null;
    state.loading = false;
  },

  getPaymentGateSuccess(state, data) {
    state.paymentGateData = data.data;
    state.error = null;
    state.loading = false;
  },

  getAdminWithdrawalsSuccess(state, data) {
    state.adminWithdrawals = data.data;
    state.paginationAdminWithdrawals = data.meta.pagination;
    state.error = null;
    state.loading = false;
  },

  getAdminWithdrawalSuccess(state, data) {
    state.adminWithdrawalData = data.data;
    state.error = null;
    state.loading = false;
  },

  rejectApproveBegin(state, { idx, type }) {
    switch (true) {
      case type == 'withdrawal':
        if (+idx === 0 || idx) {
          state.withdrawals[idx].attributes.loading = true;
        } else {
          state.withdrawalData.attributes.loading = true;
        }
        break;

      case type == 'deposit':
        if (+idx === 0 || idx) {
          state.deposits[idx].attributes.loading = true;
        } else {
          state.depositsData.attributes.loading = true;
        }
        break;

      case type == 'transfer':
        if (+idx === 0 || idx) {
          state.transfers[idx].attributes.loading = true;
        } else {
          state.transferData.attributes.loading = true;
        }
        break;

      case type == 'wallet':
        if (+idx === 0 || idx) {
          state.wallets[idx].attributes.loading = true;
        } else {
          state.withdrawalData.attributes.loading = true;
        }
        break;
    }
  },
};
