<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" v-if="isAuth" />
    <div class="layout-sidebar" @click="onSidebarClick" v-if="isAuth">
      <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
    </div>
    <div class="layout-main-container" :style="isAuth ? '' : 'margin:0;padding:0;'">
      <div class="layout-main">
        <router-view v-slot="{ Component }">
          <transition>
            <component :is="Component" />
          </transition>
        </router-view>
      </div>
    </div>
    <transition name="layout-mask">
      <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
    </transition>
  </div>
</template>

<script>
import AppTopBar from './AppTopbar.vue';
import AppMenu from './AppMenu.vue';
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      layoutMode: 'static',
      isAuth: this.$store.state.auth.status.loggedIn,
      menu: [
        {
          items: [
            {
              label: this.$t('menuItems.startPage'),
              icon: 'pi pi-info-circle',
              to: '/start-page',
            },
            {
              label: this.$t('menuItems.users'),
              icon: 'pi pi-fw pi-users',
              to: '/users?page=1',
            },
            {
              label: this.$t('menuItems.transactions'),
              icon: 'pi pi-fw pi-chart-bar',
              to: '/applications/transactions?page=1',
            },
            {
              label: this.$t('menuItems.withdrawal'),
              icon: 'pi pi-fw pi-upload',
              to: '/applications/withdrawals?page=1',
              count_name: 'withdrawals',
            },
            {
              label: this.$t('menuItems.deposit'),
              icon: 'pi pi-fw pi-download',
              to: '/applications/deposits?page=1',
            },
            {
              label: this.$t('menuItems.transfers'),
              icon: 'pi pi-fw pi-money-bill',
              to: '/applications/transfers?page=1',
              count_name: 'transfers',
            },
            {
              label: this.$t('menuItems.paymentGates'),
              icon: 'pi pi-credit-card',
              to: '/applications/payment-gates?page=1',
            },
            {
              label: this.$t('menuItems.exchange-tokens'),
              icon: 'pi pi-forward',
              to: '/applications/exchange-tokens?page=1',
            },
            {
              label: this.$t('menuItems.wallets'),
              icon: 'pi pi-wallet',
              to: '/wallets?page=1',
              count_name: 'user_wallets',
            },
            {
              label: this.$t('menuItems.accounts'),
              icon: 'pi pi-briefcase',
              to: '/accounts?page=1',
            },
            {
              label: this.$t('menuItems.trading-accounts'),
              icon: 'pi pi-lock',
              items: [
                {
                  label: this.$t('menuItems.real'),
                  icon: 'pi pi-bookmark',
                  to: '/trading-accounts/real?page=1',
                },
                {
                  label: this.$t('menuItems.demo'),
                  icon: 'pi pi-file-o',
                  to: '/trading-accounts/demo?page=1',
                },
                {
                  label: this.$t('menuItems.public'),
                  icon: 'pi pi-wifi',
                  to: '/trading/public?page=1',
                },
                {
                  label: this.$t('menuItems.invest'),
                  icon: 'pi pi-download',
                  to: '/trading/invest?page=1',
                },
                {
                  label: this.$t('menuItems.contests'),
                  icon: 'pi pi-star',
                  to: '/contests?page=1',
                },
                //{
                //  label: this.$t('menuItems.trading-logs'),
                //  icon: 'pi pi-paperclip',
                //  to: '/trading-logs?page=1',
                //},
                {
                  label: this.$t('menuItems.trading-syncing'),
                  icon: 'pi pi-share-alt',
                  to: '/trading-syncing?page=1',
                },
                {
                  label: this.$t('menuItems.trading-operations'),
                  icon: 'pi pi-sitemap',
                  to: '/trading-operations?page=1',
                },
              ],
            },
            {
              label: this.$t('menuItems.adminWithdrawals'),
              icon: 'pi pi-user-minus',
              to: '/applications/admin-withdrawals?page=1',
            },
            {
              label: this.$t('menuItems.security'),
              icon: 'pi pi-lock',
              items: [
                {
                  label: this.$t('menuItems.allSessions'),
                  icon: 'pi pi-bookmark',
                  to: '/security/sessions?page=1',
                },
                {
                  label: this.$t('menuItems.allAudits'),
                  icon: 'pi pi-file-o',
                  to: '/security/audits?page=1',
                },
              ],
            },
            {
              label: this.$t('menuItems.loot-boxes'),
              icon: 'pi pi-star',
              to: '/loot-boxes?page=1',
            },
            {
              label: this.$t('menuItems.purchases'),
              icon: 'pi pi-shopping-cart',
              to: '/purchases?page=1',
            },
            {
              label: this.$t('menuItems.telegram_users'),
              icon: 'pi pi-shopping-cart',
              to: '/telegram_users?page=1',
            },
            {
              label: this.$t('menuItems.systemSettings'),
              icon: 'pi pi-cog',
              to: '/system-settings',
            },
            {
              label: this.$t('menuItems.knowledge-base'),
              icon: 'pi pi-book',
              to: '/knowledge-base',
            },
            {
              label: this.$t('menuItems.custom-notifications'),
              icon: 'pi pi-bell',
              to: '/custom-notifications?page=1',
            },
          ],
        },
      ],
    };
  },
  // mounted() {
  //   this.getSettings();
  // },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
      this.isAuth = this.$store.state.auth.status.loggedIn;
      if (this.$route.params.themeType) {
        this.layoutMode = this.$route.params.themeType;
      }
      if (!this.isAuth) {
        this.$router.push('/login');
      }
      this.getSettings();
    },
  },
  methods: {
    ...mapActions(['getSettings']),
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;
      if (this.isDesktop()) {
        if (this.layoutMode === 'overlay') {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }
          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === 'static') {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }
      event.preventDefault();
    },
    changeMenu() {
      this.layoutMode = this.layout;
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += ' ' + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'),
          ' ',
        );
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    changeTheme() {
      let theme = this.layoutColor + '-blue';
      let themeElement = document.getElementById('theme-link');
      themeElement.setAttribute('href', themeElement.getAttribute('href').replace(this.$appState.theme, theme));
      this.$appState.theme = theme;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === 'static') return !this.staticMenuInactive;
        else if (this.layoutMode === 'overlay') return this.overlayMenuActive;
      }
      return true;
    },
  },
  computed: {
    // countWithdrawal() {
    //   return this.$store.state.global.counter.withdrawal;
    // },
    containerClass() {
      return [
        'layout-wrapper',
        {
          'layout-overlay': this.layoutMode === 'overlay',
          'layout-static': this.layoutMode === 'static',
          'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
          'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
          'layout-mobile-sidebar-active': this.mobileMenuActive,
          'p-input-filled': this.$primevue.config.inputStyle === 'filled',
          'p-ripple-disabled': this.$primevue.config.ripple === false,
          'layout-theme-light': this.$appState.theme.startsWith('saga'),
        },
      ];
    },
  },
  beforeUpdate() {
    if (this.mobileMenuActive) this.addClass(document.body, 'body-overflow-hidden');
    else this.removeClass(document.body, 'body-overflow-hidden');
  },
  components: {
    AppTopBar: AppTopBar,
    AppMenu: AppMenu,
  },
  beforeCreate() {
    this.$i18n.locale = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).settings.locale : 'ru';
    this.$router.beforeEach((to, from, next) => {
      document.title = `${this.$t('menuItems.' + to.name)} | MTHub Admin`;
      next();
    });
  },
  created() {
    this.layoutMode = this.layout;
    this.changeTheme();
    this.$http.interceptors.response.use(
      undefined,
      (err) =>
        new Promise(function () {
          if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
            this.$store.dispatch('logout');
          }
          throw err;
        }),
    );
  },
};
</script>

<style lang="scss">
@import './App.scss';
</style>
