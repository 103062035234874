export default [
  {
    path: '/notification/:id?',
    name: 'notification',
    component: () => import('@/view/notifications/NotificationData.vue'),
    props: true,
  },
  {
    path: '/notifications/',
    name: 'notifications',
    component: () => import('@/view/notifications/NotificationsCrud.vue'),
    props: true,
  },
  {
    path: '/custom-notifications/',
    name: 'custom-notifications',
    component: () => import('@/view/notifications/CustomNotifications.vue'),
    props: true,
  },
];
