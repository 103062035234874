export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/view/auth/Login.vue'),
    alias: '/',
  },
  {
    path: '/auth/second',
    name: 'authentificationSecond',
    component: () => import('@/view/auth/Authentification.vue'),
    props: true,
  },
];
