export default [
  {
    path: '/wallet/:id?',
    name: 'UserWallet',
    component: () => import('@/view/wallets/WalletsData.vue'),
    props: true,
  },
  {
    path: '/wallets/',
    name: 'wallets',
    component: () => import('@/view/wallets/WalletsCrud.vue'),
    props: true,
  },
];
