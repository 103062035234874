export default {
  getAllDashboardStatsSuccess(state, data) {
    state.dashboardStats = data.data;
    state.loading = false;
    state.error = false;
  },

  getReportBefore(state) {
    state.reportLoading = true;
  },

  getReportsSuccess(state) {
    state.reportLoading = false;
  },
  getSettingsSuccess(state, data) {
    state.settings = data;
    state.counter = data.counter;
    state.loading = false;
    state.error = false;
  },
  axiosReadErr(state, err) {
    state.reportLoading = false;
    state.loading = false;
    state.error = err;
  },
};
