import axios from 'axios';
import mutations from './mutations';
import apiLinks from '@/store/api.js';

const state = () => ({
  deposits: [],
  transfers: [],
  withdrawals: [],
  transactions: [],
  wallets: [],
  paymentGates: [],
  depositData: {},
  transferData: {},
  transactionData: {},
  withdrawalData: {},
  walletData: {},
  paymentGateData: {},
  paginationDeposit: [],
  paginationWithdrawal: [],
  paginationTransfers: [],
  exchanges: [],
  paginationExchanges: [],
  exchangeData: [],
  transfersStats: null,
  paginationTransactions: [],
  paginationWallets: [],
  paginationPaymentGates: [],
  adminWithdrawals: [],
  paginationAdminWithdrawals: [],
  adminWithdrawalData: {},
  loading: true,
  error: null,
});

const actions = {
  async getAllDeposits({ commit }, { queryLink, user_id }) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(`${apiLinks.deposits}${queryLink}${user_id ? `&user_id=${user_id}` : ''}`);
      commit('getDepositsSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },

  async showDeposit({ commit }, id) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(`${apiLinks.deposits}${id}`);
      commit('getDepositSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },

  async rejectDeposit({ commit }, { idx, id }) {
    try {
      axios.delete(`${apiLinks.deposits}${id}`);
      commit('rejectDepositSuccess', idx);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },

  async approveDeposit({ commit }, { idx, id }) {
    try {
      axios.post(`${apiLinks.deposits}${id}`);
      commit('approveDepositSuccess', idx);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },

  async getAllWithdrawals({ commit }, { queryLink, user_id }) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(`${apiLinks.withdrawals}${queryLink}${user_id ? `&user_id=${user_id}` : ''}`);
      commit('getWithdrawalsSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },

  async showWithdrawal({ commit }, id) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(`${apiLinks.withdrawals}${id}`);
      commit('getWithdrawalSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },

  async rejectWithdrawal({ commit }, { idx, id, comment }) {
    try {
      commit('rejectApproveBegin', { idx });
      const deletableEntinity = { comment };
      await axios.delete(`${apiLinks.withdrawals}${id}`, {
        data: deletableEntinity,
      });
      commit('rejectWithdrawalSuccess', { idx, comment });
    } catch (err) {
      commit('axiosReadErr', err);
    }
  },

  async approveWithdrawal({ commit }, { idx, id }) {
    try {
      await axios.post(`${apiLinks.withdrawals}${id}/approve`);
      commit('approveWithdrawalSuccess', idx);
    } catch (err) {
      commit('axiosReadErr', err);
    }
  },

  async runWithdrawal({ commit }, { idx, id }) {
    try {
      await axios.post(`${apiLinks.withdrawals}${id}/run`);
      commit('runWithdrawalSuccess', idx);
    } catch (err) {
      commit('axiosReadErr', err);
    }
  },

  async getAllTransfers({ commit }, { queryLink, user_id, accountId }) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(
        `${apiLinks.transfers}${queryLink}${user_id ? `&user_id=${user_id}` : ''}${
          accountId ? `&account_id=${accountId}` : ''
        }`,
      );
      await commit('getTransfersSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
  async getAllExchanges({ commit }, { queryLink, user_id, accountId }) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(
        `${apiLinks.exchanges}${queryLink}${user_id ? `&user_id=${user_id}` : ''}${
          accountId ? `&account_id=${accountId}` : ''
        }`,
      );
      await commit('getExchangesSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },

  async showTransfer({ commit }, id) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(`${apiLinks.transfers}${id}`);
      commit('getTransferSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
  async showExchange({ commit }, id) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(`${apiLinks.exchanges}${id}`);
      commit('getExchangeSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
  async rejectTransfer({ commit }, { idx, id }) {
    try {
      await axios.delete(`${apiLinks.transfers}${id}`);
      commit('rejectTransferSuccess', idx);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },

  async approveTransfer({ commit }, { idx, id }) {
    try {
      await axios.post(`${apiLinks.transfers}${id}`);
      commit('approveTransferSuccess', idx);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },

  async getAllTransactions({ commit }, { queryLink, accountId }) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(
        `${apiLinks.transactions}${queryLink}${accountId ? '&account_id=' + accountId : ''}`,
      );
      commit('getTransactionsSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },

  async showTransaction({ commit }, id) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(`${apiLinks.transactions}${id}`);
      commit('getTransactionSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },

  async getAllWallets({ commit }, { queryLink, user_id }) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(`${apiLinks.wallets}${queryLink}${user_id ? `&user_id=${user_id}` : ''}`);
      commit('getWalletsSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },

  async showWallet({ commit }, id) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(`${apiLinks.wallets}${id}`);
      commit('getWalletSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },

  async rejectWallet({ commit }, { idx, id, comment }) {
    try {
      const deletableEntinity = { comment };
      axios.delete(`${apiLinks.wallets}${id}`, {
        data: deletableEntinity,
      });
      commit('rejectWalletSuccess', { idx, comment });
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },

  async approveWallet({ commit }, { idx, id }) {
    try {
      axios.post(`${apiLinks.wallets}${id}`);
      commit('approveWalletSuccess', idx);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },

  async getAllPaymentGates({ commit }, { queryLink }) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(`${apiLinks.payment_gates}${queryLink}`);
      commit('getPaymentGatesSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },

  async showPaymentGate({ commit }, id) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(`${apiLinks.payment_gates}${id}`);
      commit('getPaymentGateSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },

  async getAllAdminWithdrawals({ commit }, { queryLink, id }) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(`${apiLinks.admin_withdrawals}${queryLink}${id ? '&user_id=' + id : ''}`);
      commit('getAdminWithdrawalsSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },

  async showAdminWithdrawal({ commit }, id) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(`${apiLinks.admin_withdrawals}${id}`);
      commit('getAdminWithdrawalSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
