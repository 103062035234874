export default {
  axiosReadBegin(state) {
    state.loading = true;
  },
  clearPartners(state) {
    state.loading = true;
    state.partners = [];
  },

  axiosPartnersReadSuccess(state, data) {
    state.partners = data;
    state.loading = false;
  },

  axiosPartnersReadErr(state, err) {
    state.error = err;
    state.loading = false;
  },
};
