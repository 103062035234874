import AuthService from '../config/service/auth.service';

const user = JSON.parse(localStorage.getItem('user'));
const initState = user ? { status: { loggedIn: true }, user } : { status: { loggedIn: false }, user: null };

export const auth = {
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        (user) => {
          if (!user.success) {
            commit('loginSuccess', user);
          }
          return Promise.resolve(user);
        },
        (error) => {
          commit('loginFailure');
          return Promise.reject(error);
        },
      );
    },

    refresh({ state, commit }) {
      try {
        return AuthService.refresh(state.user.refresh).then(
          (user) => {
            commit('loginSuccess', user);
            return Promise.resolve(user);
          },
          (error) => {
            commit('loginFailure');
            return Promise.reject(error);
          },
        );
      } catch (error) {
        console.log(error);
      }
    },
    logout({ commit }) {
      return AuthService.logout().then(commit('logout'));
    },
    AuthSec({ commit }, user) {
      return AuthService.authSecond(user).then(
        (user) => {
          if (!user.success) {
            commit('AuthSecSuccess', user);
            return Promise.resolve(user);
          }
        },
        (error) => {
          commit('AuthSecFailure');
          return Promise.reject(error);
        },
      );
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        (response) => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        (error) => {
          commit('registerFailure');
          return Promise.reject(error);
        },
      );
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    AuthSecSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    AuthSecFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
  },
  namespaced: true,
  state: initState,
};
