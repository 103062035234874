/**/
export default {
  data() {
    return {
      errorMessage: [],
      layout: localStorage.getItem('layout') ? localStorage.getItem('layout') : 'static',
      layoutColor: localStorage.getItem('layoutColor') ? localStorage.getItem('layoutColor') : 'saga',
      mainLanguage: localStorage.getItem('language') == 'ru' ? 'ru-RU' : 'en-US',
      perPages: [25, 50, 100],
      priorities: {
        standard: 5,
        accelerated: 3,
        urgent: 2,
      },
      countries: [
        {
          value: 'AF',
        },
        {
          value: 'AX',
        },
        {
          value: 'AL',
        },
        {
          value: 'DZ',
        },
        {
          value: 'AS',
        },
        {
          value: 'AD',
        },
        {
          value: 'AO',
        },
        {
          value: 'AI',
        },
        {
          value: 'AQ',
        },
        {
          value: 'AG',
        },
        {
          value: 'AR',
        },
        {
          value: 'AM',
        },
        {
          value: 'AW',
        },
        {
          value: 'AU',
        },
        {
          value: 'AT',
        },
        {
          value: 'AZ',
        },
        {
          value: 'BS',
        },
        {
          value: 'BH',
        },
        {
          value: 'BD',
        },
        {
          value: 'BB',
        },
        {
          value: 'BY',
        },
        {
          value: 'BE',
        },
        {
          value: 'BZ',
        },
        {
          value: 'BJ',
        },
        {
          value: 'BM',
        },
        {
          value: 'BT',
        },
        {
          value: 'BO',
        },
        {
          value: 'BA',
        },
        {
          value: 'BW',
        },
        {
          value: 'BV',
        },
        {
          value: 'BR',
        },
        {
          value: 'IO',
        },
        {
          value: 'VG',
        },
        {
          value: 'BN',
        },
        {
          value: 'BG',
        },
        {
          value: 'BF',
        },
        {
          value: 'BI',
        },
        {
          value: 'KH',
        },
        {
          value: 'CM',
        },
        {
          value: 'CA',
        },
        {
          value: 'CV',
        },
        {
          value: 'BQ',
        },
        {
          value: 'KY',
        },
        {
          value: 'CF',
        },
        {
          value: 'TD',
        },
        {
          value: 'CL',
        },
        {
          value: 'CN',
        },
        {
          value: 'CX',
        },
        {
          value: 'CC',
        },
        {
          value: 'CO',
        },
        {
          value: 'KM',
        },
        {
          value: 'CG',
        },
        {
          value: 'CD',
        },
        {
          value: 'CK',
        },
        {
          value: 'CR',
        },
        {
          value: 'CI',
        },
        {
          value: 'HR',
        },
        {
          value: 'CU',
        },
        {
          value: 'CW',
        },
        {
          value: 'CY',
        },
        {
          value: 'CZ',
        },
        {
          value: 'DK',
        },
        {
          value: 'DJ',
        },
        {
          value: 'DM',
        },
        {
          value: 'DO',
        },
        {
          value: 'EC',
        },
        {
          value: 'EG',
        },
        {
          value: 'SV',
        },
        {
          value: 'GQ',
        },
        {
          value: 'ER',
        },
        {
          value: 'EE',
        },
        {
          value: 'SZ',
        },
        {
          value: 'ET',
        },
        {
          value: 'FK',
        },
        {
          value: 'FO',
        },
        {
          value: 'FJ',
        },
        {
          value: 'FI',
        },
        {
          value: 'FR',
        },
        {
          value: 'GF',
        },
        {
          value: 'PF',
        },
        {
          value: 'TF',
        },
        {
          value: 'GA',
        },
        {
          value: 'GM',
        },
        {
          value: 'GE',
        },
        {
          value: 'DE',
        },
        {
          value: 'GH',
        },
        {
          value: 'GI',
        },
        {
          value: 'GR',
        },
        {
          value: 'GL',
        },
        {
          value: 'GD',
        },
        {
          value: 'GP',
        },
        {
          value: 'GU',
        },
        {
          value: 'GT',
        },
        {
          value: 'GG',
        },
        {
          value: 'GN',
        },
        {
          value: 'GW',
        },
        {
          value: 'GY',
        },
        {
          value: 'HT',
        },
        {
          value: 'HM',
        },
        {
          value: 'HN',
        },
        {
          value: 'HK',
        },
        {
          value: 'HU',
        },
        {
          value: 'IS',
        },
        {
          value: 'IN',
        },
        {
          value: 'ID',
        },
        {
          value: 'IR',
        },
        {
          value: 'IQ',
        },
        {
          value: 'IE',
        },
        {
          value: 'IM',
        },
        {
          value: 'IL',
        },
        {
          value: 'IT',
        },
        {
          value: 'JM',
        },
        {
          value: 'JP',
        },
        {
          value: 'JE',
        },
        {
          value: 'JO',
        },
        {
          value: 'KZ',
        },
        {
          value: 'KE',
        },
        {
          value: 'KI',
        },
        {
          value: 'KW',
        },
        {
          value: 'KG',
        },
        {
          value: 'LA',
        },
        {
          value: 'LV',
        },
        {
          value: 'LB',
        },
        {
          value: 'LS',
        },
        {
          value: 'LR',
        },
        {
          value: 'LY',
        },
        {
          value: 'LI',
        },
        {
          value: 'LT',
        },
        {
          value: 'LU',
        },
        {
          value: 'MO',
        },
        {
          value: 'MG',
        },
        {
          value: 'MW',
        },
        {
          value: 'MY',
        },
        {
          value: 'MV',
        },
        {
          value: 'ML',
        },
        {
          value: 'MT',
        },
        {
          value: 'MH',
        },
        {
          value: 'MQ',
        },
        {
          value: 'MR',
        },
        {
          value: 'MU',
        },
        {
          value: 'YT',
        },
        {
          value: 'MX',
        },
        {
          value: 'FM',
        },
        {
          value: 'MD',
        },
        {
          value: 'MC',
        },
        {
          value: 'MN',
        },
        {
          value: 'ME',
        },
        {
          value: 'MS',
        },
        {
          value: 'MA',
        },
        {
          value: 'MZ',
        },
        {
          value: 'MM',
        },
        {
          value: 'NA',
        },
        {
          value: 'NR',
        },
        {
          value: 'NP',
        },
        {
          value: 'NL',
        },
        {
          value: 'NC',
        },
        {
          value: 'NZ',
        },
        {
          value: 'NI',
        },
        {
          value: 'NE',
        },
        {
          value: 'NG',
        },
        {
          value: 'NU',
        },
        {
          value: 'NF',
        },
        {
          value: 'KP',
        },
        {
          value: 'MK',
        },
        {
          value: 'MP',
        },
        {
          value: 'NO',
        },
        {
          value: 'OM',
        },
        {
          value: 'PK',
        },
        {
          value: 'PW',
        },
        {
          value: 'PS',
        },
        {
          value: 'PA',
        },
        {
          value: 'PG',
        },
        {
          value: 'PY',
        },
        {
          value: 'PE',
        },
        {
          value: 'PH',
        },
        {
          value: 'PN',
        },
        {
          value: 'PL',
        },
        {
          value: 'PT',
        },
        {
          value: 'PR',
        },
        {
          value: 'QA',
        },
        {
          value: 'RE',
        },
        {
          value: 'RO',
        },
        {
          value: 'RU',
        },
        {
          value: 'RW',
        },
        {
          value: 'WS',
        },
        {
          value: 'SM',
        },
        {
          value: 'ST',
        },
        {
          value: 'SA',
        },
        {
          value: 'SN',
        },
        {
          value: 'RS',
        },
        {
          value: 'SC',
        },
        {
          value: 'SL',
        },
        {
          value: 'SG',
        },
        {
          value: 'SX',
        },
        {
          value: 'SK',
        },
        {
          value: 'SI',
        },
        {
          value: 'SB',
        },
        {
          value: 'SO',
        },
        {
          value: 'ZA',
        },
        {
          value: 'GS',
        },
        {
          value: 'KR',
        },
        {
          value: 'SS',
        },
        {
          value: 'ES',
        },
        {
          value: 'LK',
        },
        {
          value: 'BL',
        },
        {
          value: 'SH',
        },
        {
          value: 'KN',
        },
        {
          value: 'LC',
        },
        {
          value: 'MF',
        },
        {
          value: 'PM',
        },
        {
          value: 'VC',
        },
        {
          value: 'SD',
        },
        {
          value: 'SR',
        },
        {
          value: 'SJ',
        },
        {
          value: 'SE',
        },
        {
          value: 'CH',
        },
        {
          value: 'SY',
        },
        {
          value: 'TW',
        },
        {
          value: 'TJ',
        },
        {
          value: 'TZ',
        },
        {
          value: 'TH',
        },
        {
          value: 'TL',
        },
        {
          value: 'TG',
        },
        {
          value: 'TK',
        },
        {
          value: 'TO',
        },
        {
          value: 'TT',
        },
        {
          value: 'TN',
        },
        {
          value: 'TR',
        },
        {
          value: 'TM',
        },
        {
          value: 'TC',
        },
        {
          value: 'TV',
        },
        {
          value: 'UM',
        },
        {
          value: 'VI',
        },
        {
          value: 'UG',
        },
        {
          value: 'UA',
        },
        {
          value: 'AE',
        },
        {
          value: 'GB',
        },
        {
          value: 'US',
        },
        {
          value: 'UY',
        },
        {
          value: 'UZ',
        },
        {
          value: 'VU',
        },
        {
          value: 'VA',
        },
        {
          value: 'VE',
        },
        {
          value: 'VN',
        },
        {
          value: 'WF',
        },
        {
          value: 'EH',
        },
        {
          value: 'YE',
        },
        {
          value: 'ZM',
        },
        {
          value: 'ZW',
        },
      ],
    };
  },
  watch: {
    mainLanguage() {
      localStorage.setItem('language', this.mainLanguage);
    },
  },
  computed: {
    queryLink() {
      const query = this.$route.query;
      let link = query.page ? `?page=${query.page}` : '?page=1';
      if (!query.per_page) {
        link += '&per_page=25';
      }
      for (const [paramsName, queryParam] of Object.entries(query)) {
        switch (paramsName) {
          case 'page':
            break;
          case 'created_at':
          case 'due_at':
            //eslint-disable-next-line
            const calendar = queryParam.split(',');
            if (calendar[1] && calendar[0]) {
              link += `&${paramsName}_after=${new Date(calendar[0]).toLocaleDateString(
                'en-GB',
              )}&${paramsName}_before=${new Date(calendar[1]).toLocaleDateString('en-GB')}`;
            } else {
              link += `&${paramsName}_after=${new Date(calendar[0]).toLocaleDateString(
                'en-GB',
              )}&${paramsName}_before=${new Date(calendar[0]).toLocaleDateString('en-GB')}`;
            }
            break;
          default:
            for (const val of queryParam.split(',')) {
              link += `&${paramsName}=${val}`;
            }
            break;
        }
      }
      return link;
    },

    filterLink() {
      let link = '';
      for (const [paramsName, queryParam] of Object.entries(this.$route.query)) {
        switch (paramsName) {
          case 'page':
          case 'per_page':
            break;
          case 'created_at':
          case 'due_at':
            //eslint-disable-next-line
            const calendar = queryParam.split(',');
            if (calendar[1] && calendar[0]) {
              link += `&${paramsName}_after=${new Date(calendar[0]).toLocaleDateString(
                'en-GB',
              )}&${paramsName}_before=${new Date(calendar[1]).toLocaleDateString('en-GB')}`;
            } else {
              link += `&${paramsName}_after=${new Date(calendar[0]).toLocaleDateString(
                'en-GB',
              )}&${paramsName}_before=${new Date(calendar[0]).toLocaleDateString('en-GB')}`;
            }
            break;
          default:
            for (const val of queryParam.split(',')) {
              link += `&${paramsName}=${val}`;
            }
            break;
        }
      }
      return link;
    },
  },
  methods: {
    adminCheckMail(email) {
      this.errorMessage = [];
      if (this.checkSymbols(email)) {
        this.errorMessage.push('Email содержит специальные символы');
      } else if (this.validateEmail(email)) {
        this.errorMessage = [];
      } else {
        this.errorMessage.push('Введите Email верно');
      }
    },

    errorValidator(error) {
      this.errorMessage = [];
      if (typeof error === 'object') {
        Object.entries(error).map((value) => {
          if (value[0] != 'password' && value[0] != 'errors') {
            this.errorMessage.push(
              this.$t('actions.user.titles.' + value[0]) + ' ' + (typeof value[1] == 'object' ? value[1][0] : value[1]),
            );
          } else {
            this.errorMessage.push(typeof value[1] == 'object' ? value[1][0] : value[1]);
          }
        });
      } else {
        this.errorMessage.push(error);
      }
    },

    adminCheckData(data) {
      this.errorMessage = [];
      let i = 0;
      this.errorMessage.push('поля со специальными символами: ');
      for (const [key, value] of Object.entries(data)) {
        if (this.checkSymbols(value)) {
          i++;
          this.errorMessage[0] += `${key} `;
        }
      }
      if (i == 0) {
        this.errorMessage = '';
      }
    },
    validateEmail(email) {
      const re =
        //eslint-disable-next-line
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    checkSymbols(str) {
      //eslint-disable-next-line
      const regex = /[!#$%^&*()+\=\[\]{};':"\\|,<>\/?`]/g;
      return regex.test(str);
    },
    switchLanguage() {
      this.mainLanguage = this.isRuLang ? 'ru' : 'en';
    },
  },
};
