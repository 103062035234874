import axios from 'axios';
import mutations from './mutations';
import apiLinks from '@/store/api.js';

const state = () => ({
  data: [],
  pagination: [],
  dataContest: null,
  membersData: [],
  membersMeta: [],
  loading: true,
  error: null,
  message: null,
});

const actions = {
  async contestsGetData({ commit }, { queryLink, id }) {
    try {
      commit('contestsReadBegin');
      const query = await axios.get(`${apiLinks.trade}${apiLinks.contests}${queryLink}${id ? '&user_id=' + id : ''}`);

      await commit('contestsReadSuccess', query.data);
    } catch (err) {
      await commit('contestsReadErr', err);
    }
  },

  async getDataContest({ commit }, id) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(`${apiLinks.trade}${apiLinks.contests}${id}`);
      await commit('oneContestReadSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
  async getMembersContest({ commit }, { id, queryLink }) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(
        `${apiLinks.trade}${apiLinks.contest_members}${queryLink}${id ? '&contest_id=' + id : ''}`,
      );
      await commit('membersReadSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },

  async createContest({ commit }, { kind, start_at }) {
    try {
      const query = await axios.post(`${apiLinks.trade}${apiLinks.contests}`, { kind, start_at });
      await commit('endContestChange', query.data);
    } catch (err) {
      await commit('endContestChange', err);
    }
  },
  async changeContest({ commit }, { id, changeType }) {
    try {
      const query = await axios.patch(`${apiLinks.trade}${apiLinks.contests}${id}/${changeType}`);
      await commit('endContestChange', query.data);
    } catch (err) {
      await commit('endContestChange', err);
    }
  },
  // Дублирует withdrawAccount и не понятно где используется
  // async withdrawAccount({ commit }, { id, amount, comment }) {
  //   try {
  //     const query = await axios.post(`${apiLinks.trade_accounts}${id}/${apiLinks.withdraw}`, {
  //       amount: +amount,
  //       comment,
  //     });
  //     await commit('axiosAccountTopUp', query.data);
  //   } catch (err) {
  //     await commit('axiosAccountTopUp', err);
  //   }
  // },
  async getGraphStats({ commit }, id) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(`${apiLinks.trade_accounts}${id}/${apiLinks.graph}`);
      await commit('axiosAccountGraphStats', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
