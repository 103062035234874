export default [
  {
    path: '/trading-accounts/:type/:deposit?/',
    name: 'trading-accounts',
    props: true,
    component: () => import('@/view/trading-accounts/TradingAccountsCrud.vue'),
  },
  {
    path: '/trading-account/:id',
    name: 'trading-account',
    props: true,
    component: () => import('@/view/trading-accounts/TradingAccountData.vue'),
  },
  {
    path: '/trading/:type/:deposit?/',
    name: 'public',
    props: true,
    component: () => import('@/view/trading-accounts/PublicAccountsCrud.vue'),
  },
  {
    path: '/trading/:type/:id',
    name: 'public-account',
    props: true,
    component: () => import('@/view/trading-accounts/PublicAccountData.vue'),
  },
  {
    path: '/trading-logs/',
    name: 'trading-logs',
    props: true,
    component: () => import('@/view/trading-accounts/TradingLogsCrud.vue'),
  },
  {
    path: '/trading-syncing/',
    name: 'trading-syncing',
    props: true,
    component: () => import('@/view/trading-accounts/TradingSyncingCrud.vue'),
  },
  {
    path: '/trading-operations/',
    name: 'trading-operations',
    props: true,
    component: () => import('@/view/trading-accounts/TradingOperationsCrud.vue'),
  },
];
