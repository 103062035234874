import axios from 'axios';
import store from '@/store';
import router from '@/router';

export default function axiosSetup() {
  // axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  axios.interceptors.request.use(
    async (config) => {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        config.headers = {
          Authorization: user.token,
          Accept: 'application/json',
        };
      }
      return config;
    },
    (error) => Promise.reject(error),
  );
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && originalRequest.url.includes('auth/refresh')) {
        store.dispatch('auth/logout');
        router.push('/login');
        return Promise.reject(error);
      }
      if (
        error.response.status === 401 &&
        !originalRequest._retry &&
        !originalRequest.url.includes('auth/login') &&
        !originalRequest.url.includes('auth/otp')
      ) {
        originalRequest._retry = true;
        const user = await store.dispatch('auth/refresh');
        axios.defaults.headers.common.Authorization = user.token;
        return axios(originalRequest);
      }
      return Promise.reject(error);
    },
  );
}
