import axios from 'axios';
import mutations from './mutations';
import apiLinks from '@/store/api.js';

const state = () => ({
  data: [],
  dataAccount: {},
  url: null,
  pagination: [],
  fileLoading: false,
  loading: true,
  error: null,
  message: null,
  settings: null,
});

const actions = {
  async accountsGetData({ commit }, { queryLink }) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(`${apiLinks.accounts}${queryLink}`);
      await commit('axiosReadSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
  async getDataAccount({ commit }, id) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(`${apiLinks.accounts}${id}`);
      await commit('axiosReadAccSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
  async topUpAccount({ commit }, data) {
    try {
      const query = await axios.post(`${apiLinks.accounts}${data.id}/${apiLinks.to_pup}`, { ...data });
      await commit('axiosAccountTopUp', query.data);
    } catch (err) {
      await commit('axiosAccountTopUp', err.response.data);
    }
  },
  async withdrawAccount({ commit }, data) {
    try {
      const query = await axios.post(`${apiLinks.accounts}${data.id}/${apiLinks.withdraw}`, { ...data });
      await commit('axiosAccountTopUp', query.data);
    } catch (err) {
      await commit('axiosAccountTopUp', err.response.data);
    }
  },

  async getAccountSettings({ commit }) {
    try {
      commit('axiosReadBegin');

      const query = await axios.get(`${apiLinks.url + apiLinks.settings}`);

      await commit('getSettingsSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
