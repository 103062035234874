export default {
  purchasesBegin(state) {
    state.loading = true;
    state.loadingGift = true;
  },

  purchasesReadSuccess(state, data) {
    state.data = data.data;
    state.pagination = data.meta.pagination;
    state.loading = false;
  },

  lootBoxErr(state, err) {
    state.error = err;
    state.loading = false;
    state.loadingGift = false;
  },
};
