import axios from 'axios';
import mutations from './mutations';
import apiLinks from '@/store/api.js';

const state = () => ({
  adminSessionsData: [],
  auditsData: [],
  allSessionsData: [],
  auditData: [],
  sessionData: {},
  url: null,
  paginationAdmin: [],
  paginationAll: [],
  paginationAudits: [],
  error: null,
  configsData: null,
  configData: null,
  conf: null,
  loading: true,
});

const actions = {
  async securityGetAdminSessions({ commit }, { queryLink }) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(`${apiLinks.security}${queryLink}`);
      await commit('getAdminSessionsSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
  async securityGetAllSessions({ commit }, { queryLink, id }) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(`${apiLinks.sessions}${queryLink}${id ? '&user_id=' + id : ''}`);
      await commit('getAllSessionsSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
  async securityGetSession({ commit }, id) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(apiLinks.security + id);
      await commit('getSessionSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
  async securityCloseSession({ commit }, { adminIdx, id }) {
    try {
      await axios.delete(apiLinks.security + id);
      commit('closeSessionAdminSuccess', { adminIdx });
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
  async securityCloseAllSessions({ commit }) {
    try {
      axios.delete(apiLinks.security + apiLinks.delete_all);
    } catch (err) {
      commit('axiosReadErr', err);
    }
  },
  async securityGetAllAudits({ commit }, { queryLink }) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(`${apiLinks.audits}${queryLink}`);
      await commit('getAllAuditsSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
  async securityGetAudit({ commit }, id) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(apiLinks.audits + id);
      await commit('getAuditSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },

  async securityGetConfigs({ commit }) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(apiLinks.security_configs);
      await commit('getConfigsSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },

  async securitySendConfig({ commit }, { id, config }) {
    try {
      const query = await axios.put(apiLinks.security_configs + id, {
        configuration: config,
      });
      await commit('sendConfigSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
  async securityCloseUserSession({ commit }, { userIdx, id, session_id }) {
    try {
      await axios.delete(apiLinks.users + id + '/' + apiLinks.link_sessions + session_id);
      commit('closeSessionUserSuccess', { userIdx });
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
  async securityCloseAllUserSessions({ commit }, id) {
    try {
      await axios.delete(apiLinks.users + id + '/' + apiLinks.link_sessions + apiLinks.delete_all);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
