export default {
  lootBoxesBegin(state) {
    state.loading = true;
    state.loadingGift = true;
  },

  lootBoxesReadSuccess(state, data) {
    state.data = data.data;
    state.pagination = data.meta.pagination;
    state.loading = false;
    state.loadingGift = false;
  },

  oneLootBoxReadSuccess(state, data) {
    state.dataLootBox = data.data;
    state.loading = false;
    state.loadingGift = false;
  },

  lootBoxErr(state, err) {
    state.error = err;
    state.loading = false;
    state.loadingGift = false;
  },

  giftLootBoxSuccess(state, data) {
    state.dataLootBox = [...state.dataLootBox, data.data];
    state.loadingGift = false;
  },
};
