import { createRouter, createWebHistory } from 'vue-router';
import authPage from './routes/authPage';
import accounts from './routes/Accounts';
import admin from './routes/Admin';
import applications from './routes/Applications';
import notifications from './routes/Notifications';
import security from './routes/Security';
import users from './routes/Users';
import wallets from './routes/Wallets';
import global from './routes/Global';
import knowledge from './routes/Knowledge';
import tradingAccounts from './routes/TradingAccounts';
import contests from './routes/Contents.js';
import loot_boxes from './routes/LootBoxes';
import shop from './routes/Shop';
import telegramUsers from './routes/TelegramUsers';

const routes = [
  ...authPage,
  ...accounts,
  ...admin,
  ...applications,
  ...notifications,
  ...security,
  ...users,
  ...wallets,
  ...global,
  ...knowledge,
  ...tradingAccounts,
  ...contests,
  ...loot_boxes,
  ...shop,
  ...telegramUsers,
];

const router = createRouter({
  base: '/',
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.name != 'user' && to.name != 'Account' && to.name != 'trading-account') {
      return { left: 0, top: 0 };
    }
  },
});

export default router;
