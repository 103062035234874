export default {
  axiosReadBegin(state) {
    state.loading = true;
  },

  axiosReadSuccess(state, data) {
    state.data = data.data;
    state.pagination = data.meta.pagination;
    state.loading = false;
  },
  axiosAccountTopUp(state, data) {
    state.message = data.error;
  },
  axiosReadAccSuccess(state, data) {
    state.dataAccount = data.data;
    state.loading = false;
  },
  axiosReadErr(state, err) {
    state.error = err;
    state.loading = false;
  },
  getSettingsSuccess(state, data) {
    state.settings = data;
    state.error = null;
    state.loading = false;
  },
};
