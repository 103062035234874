export default [
  {
    path: '/contest/:id?',
    name: 'contest',
    component: () => import('@/view/contests/ContestsData.vue'),
    props: true,
  },
  {
    path: '/contests',
    name: 'contests',
    component: () => import('@/view/contests/ContestsCrud.vue'),
    props: true,
  },
];
