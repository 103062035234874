import axios from 'axios';
import mutations from './mutations';
import apiLinks from '@/store/api.js';

const state = () => ({
  url: null,
  loading: true,
  error: null,
  message: null,
  dataAccount: {},
  data: [],
  pagination: [],
  logs: null,
  logsPagination: null,
  operations: null,
  operationsPagination: null,
  syncing: null,
  syncingPagination: null,
  graphData: null,
});

const actions = {
  async publicAccountsGetData({ commit }, { queryLink, type, id, trader_account_id }) {
    try {
      commit('axiosPublicReadBegin');
      const query = await axios.get(
        `${apiLinks[[type === 'invest' ? 'investor_accounts' : 'published_accounts']]}${queryLink}${
          id ? '&user_id=' + id : ''
        }${trader_account_id ? '&trader_account_id=' + trader_account_id : ''}`,
      );
      await commit('axiosReadSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
  async publicLogsGet({ commit }, { queryLink, accountId }) {
    try {
      commit('axiosPublicReadBegin');
      const query = await axios.get(
        `${apiLinks.api_logs}${queryLink}${accountId ? '&trade_account_id=' + accountId : ''}`,
      );
      await commit('axiosPublicLogsGetSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
  async publicOperationsGet({ commit }, { queryLink, accountId }) {
    try {
      commit('axiosPublicReadBegin');
      const query = await axios.get(
        `${apiLinks.trade}${apiLinks.sync_data}${queryLink}${accountId ? '&trade_account_id=' + accountId : ''}`,
      );
      await commit('axiosPublicOperationsGetSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
  async publicSyncingGet({ commit }, { queryLink, accountId }) {
    try {
      commit('axiosPublicReadBegin');
      const query = await axios.get(
        `${apiLinks.trade}${apiLinks.sync_infos}${queryLink}${accountId ? '&trade_account_id=' + accountId : ''}`,
      );
      await commit('axiosPublicSyncingGetSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
  async getPublicDataAccount({ commit }, { type, id }) {
    try {
      commit('axiosPublicReadBegin');
      const query = await axios.get(`${apiLinks[type === 'invest' ? 'investor_accounts' : 'trade_accounts']}${id}`);
      await commit('axiosReadAccSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },

  async unpublishPublicAccount({ commit }, { id }) {
    try {
      const query = await axios.patch(`${apiLinks.published_accounts}${id}/${apiLinks.unpublish}`);
      await commit('axiosAccountTopUp', query.data);
    } catch (err) {
      await commit('axiosAccountTopUp', err);
    }
  },
  async getGraphPublicStats({ commit }, { id, group_by, type }) {
    try {
      commit('axiosPublicReadBegin');
      const query = await axios.get(
        `${apiLinks[type === 'invest' ? 'investor_accounts' : 'trade_accounts']}${id}/${apiLinks.graph}${
          group_by ? '?group_by=' + group_by : ''
        }`,
      );
      await commit('axiosAccountGraphStats', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },

  async changeStatusPublicAccount({ commit }, { acc_id, type }) {
    try {
      commit('axiosPublicReadBegin');
      await axios.patch(`${apiLinks.trade_accounts}${acc_id}/${apiLinks[type]}`);
      commit('axiosPublicReadSuccess');
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
