import axios from 'axios';
import mutations from './mutations';
import apiLinks from '@/store/api.js';

const state = () => ({
  data: [],
  pagination: [],
  dataContest: null,
  membersData: [],
  membersMeta: [],
  loadingGift: false,
  loading: true,
  error: null,
  message: null,
});

const actions = {
  async telegramUsersGetData({ commit }, { queryLink, id }) {
    try {
      commit('telegramUsersBegin');
      const query = await axios.get(`${apiLinks.telegram_users}${queryLink}${id ? '&user_id=' + id : ''}`);
      await commit('telegramUsersReadSuccess', query.data);
    } catch (err) {
      await commit('telegramUsersErr', err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
