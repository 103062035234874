import axios from 'axios';
import mutations from './mutations';
import apiLinks from '@/store/api.js';

const state = () => ({
  data: {},
  adminData: [],
  loading: true,
  changeSuccess: null,
  error: null,
  adminSettings: null,
  twoFac: null,
  locale: 'en',
  twoFactorKey: null,
  mailAcc: null,
  tableSettings: {
    perPage: 25,
    NotificationPerPage: 10,
  },
});

const actions = {
  async axiosProfileGetData({ commit }) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(apiLinks.profile);
      await commit('profilePersonalDataSuccess', query.data);
    } catch (err) {
      await commit('profilePersonalDataErr', err);
    }
  },
  async axiosChangeProfileData({ commit }, changebleEntity) {
    try {
      const query = await axios.patch(apiLinks.profile + apiLinks.personal_user_data, changebleEntity);
      commit('axiosDataChangeSuccess', query);
    } catch (err) {
      await commit('axiosChangeErr', err);
    }
  },
  async axiosChangeEmail({ commit }, changebleEntity) {
    try {
      const query = await axios.patch(apiLinks.profile + apiLinks.email, changebleEntity);
      commit('axiosChangeSuccess', query.statusText);
    } catch (err) {
      await commit('axiosChangeErr', err);
    }
  },
  async axiosChangePassword({ commit }, changebleEntity) {
    try {
      const query = await axios.patch(apiLinks.profile + apiLinks.password, changebleEntity);
      commit('axiosChangeSuccess', query.statusText);
    } catch (err) {
      await commit('axiosChangeErr', err);
    }
  },
  async axiosGetSettings({ commit }) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(apiLinks.profile + apiLinks.settings);
      commit('axiosGetSuccess', query.data);
    } catch (err) {
      commit('axiosGetErr', err);
    }
  },
  async axiosChangeSettings({ commit }, { locale, time_zone }) {
    try {
      const query = await axios.post(apiLinks.profile + apiLinks.settings, {
        locale,
        time_zone,
      });
      commit('axiosChangeSettingsSuccess', query);
    } catch (err) {
      commit('axiosGetErr', err);
    }
  },
  async enableTwoFactory({ commit }) {
    try {
      const query = await axios.post(apiLinks.profile + apiLinks.two_fac);
      commit('axiosEnableTwoFac', query.data);
    } catch (err) {
      commit('axiosGetErr', err);
    }
  },

  async disableTwoFactory({ commit }) {
    try {
      const query = await axios.delete(apiLinks.profile + apiLinks.two_fac);
      commit('axiosDisableTwoFac', query.statusText);
    } catch (err) {
      commit('axiosGetErr', err);
    }
  },
  async mailAccept({ commit }, confirmation_token) {
    try {
      const query = await axios.get(apiLinks.mail, {
        params: {
          confirmation_token,
        },
      });
      commit('mailAccepted', query.data);
    } catch (err) {
      commit('axiosGetErr', err);
    }
  },
  changeSettings({ commit }, data) {
    commit('changeTableData', data);
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
