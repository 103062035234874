<template>
  <div class="layout-topbar">
    <router-link to="/" class="layout-topbar-logo">
      <span>MTHub Admin</span>
    </router-link>
    <button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
      <i class="pi pi-bars"></i>
    </button>

    <button
      class="p-link layout-topbar-menu-button layout-topbar-button"
      v-styleclass="{
        selector: '@next',
        enterClass: 'hidden',
        enterActiveClass: 'scalein',
        leaveToClass: 'hidden',
        leaveActiveClass: 'fadeout',
        hideOnOutsideClick: true,
      }"
    >
      <i class="pi pi-ellipsis-v"></i>
    </button>
    <ul class="layout-topbar-menu hidden lg:flex origin-top">
      <li>
        <router-link to="/admin-account">
          <p style="margin-top: 12px" v-if="adminData.user_personal_data">
            {{ adminData.user_personal_data.last_name }}
            {{ adminData.user_personal_data.first_name }}
            {{ adminData.user_personal_data.middle_name }}
          </p>
        </router-link>
      </li>
      <li class="notification" v-click-outside="closeNotification">
        <button class="p-link layout-topbar-button notification__button" @click="toggleNotification">
          <i class="pi pi-bell"></i>
          <span>Notifications</span>
        </button>
        <span
          class="new-notification-col"
          :class="{ 'new-notification-col_active': newNotifications > 0 }"
          @click="toggleNotification"
          >{{ newNotifications }}</span
        >
        <div class="notification__body card" v-if="isNotificationActive">
          <p class="notification__header">
            {{ $t('notifications.notifications') }}
          </p>
          <div class="notification__wrapper" @scroll="scrollNotification">
            <div
              class="notification__inner"
              v-for="notification of notifications"
              :key="notification.id"
              :class="{
                notification__inner_unread: !notification.attributes.read_at,
              }"
            >
              <router-link :to="'/notification/' + notification.id">
                <p
                  class="notification__text"
                  @click="closeNotification"
                  @mouseover="readThisNotification(notification.id)"
                >
                  {{ notification.attributes.message }}
                </p>
              </router-link>
            </div>
          </div>
          <div class="notification__link">
            <router-link to="/notifications?page=1">
              <p @click="closeNotification">
                {{ $t('notifications.incomingActivity') }}
              </p>
            </router-link>
          </div>
        </div>
      </li>
      <li>
        <button class="p-link layout-topbar-button" @click="goToLogout">
          <i class="pi pi-sign-out"></i>
          <span>Logout</span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      isNotificationActive: false,
    };
  },
  channels: {
    'Noticed::NotificationChannel': {
      connected() {},
      received(data) {
        this.getNewDataNotification(data);
      },
      rejected() {
        this.connectWithRefreshedToken();
      },
      disconnected() {
        this.$cable.connection.disconnect();
      },
    },
  },
  mounted() {
    this.connectWithRefreshedToken();
    this.$cable.subscribe({
      channel: 'Noticed::NotificationChannel',
    });
    this.axiosProfileGetData();
    this.reloadNotifications();
  },
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions([
      'axiosProfileGetData',
      'getAllTosterNotifications',
      'readNotification',
      'addNotification',
      'getNewDataNotification',
    ]),

    connectWithRefreshedToken() {
      this.$cable.connection.disconnect();
      this.$cable.connection.connect(
        process.env.VUE_APP_API_WEBSOCKET_URL + '?token=' + JSON.parse(localStorage.getItem('user')).token,
      );
    },
    onMenuToggle(event) {
      this.$emit('menu-toggle', event);
    },
    onTopbarMenuToggle(event) {
      this.$emit('topbar-menu-toggle', event);
    },
    reloadNotifications() {
      this.getAllTosterNotifications(this.$settings.NotificationPerPage);
    },
    async readThisNotification(id) {
      await this.readNotification(id);
      setTimeout(() => {
        this.reloadNotifications();
      });
    },
    scrollNotification(event) {
      let scrollTop = event.target.scrollTop,
        scrollHeight = event.target.scrollHeight,
        height = event.target.offsetHeight,
        perPage = this.$settings.NotificationPerPage;
      if (scrollTop + 50 > scrollHeight - height && height == 500 && perPage <= this.notifications.length) {
        this.$settings.NotificationPerPage += 10;
        this.reloadNotifications();
      }
    },
    toggleNotification() {
      this.isNotificationActive = !this.isNotificationActive;
    },
    closeNotification() {
      this.isNotificationActive = false;
    },
    goToLogout() {
      this.logout();
      this.$router.push('login');
    },
  },
  computed: mapState({
    locale: (state) => state.profile.locale,
    notifications: (state) => state.notifications.tosterNotifications,
    newNotifications: (state) => state.notifications.newNotifications,
    $settings: (state) => state.profile.tableSettings,
    adminData: (state) => state.profile.adminData,
  }),
};
</script>
<style scoped lang="scss">
.layout-topbar .layout-topbar-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.notification {
  position: relative;
  color: var(--text-color);
  .new-notification-col {
    position: absolute;
    color: #fff;
    background-color: #0388e5;
    border: 1px solid #07bdf4;
    border-radius: 50%;
    font-size: 10px;
    bottom: 3px;
    right: 2px;
    padding: 2px 5px;
    &_active {
      animation: 1s linear 0s infinite alternate notification__active;
    }
  }
  &__header {
    padding: 10px 0;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    margin: 0;
  }
  &__body {
    position: absolute;
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.2);
    top: 110%;
    right: -100px;
    width: 400px;
    padding: 10px 0px !important;
  }
  &__text {
    margin: 0;
    cursor: pointer;
    color: var(--text-color);
    transition: 0.1s linear;

    &:hover {
      color: #0388e5;
      text-decoration: underline;
      transition: 0.1s linear;
    }
  }
  &__wrapper {
    max-height: 500px;
    overflow: auto;
    width: 100%;
    &::-webkit-scrollbar {
      width: 7px;
      background: linear-gradient(to bottom, #0388e5 0%, #07bdf4 100%);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.6);
      border-radius: 5px;
    }
  }
  &__inner {
    display: flex;
    position: relative;
    transition: 0.1s linear;

    padding: 20px;
    &_unread {
      background-color: rgba(7, 189, 244, 0.2);
    }
    &:hover {
      transition: 0.1s linear;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 2px;
    }
  }
  &__link {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px 0;
    text-align: center;
    display: flex;
    justify-content: center;
    p {
      cursor: pointer;
      transition: 0.1s linear;
      &:hover {
        color: #0388e5;
        text-decoration: underline;
        transition: 0.1s linear;
      }
    }
  }
}
@keyframes notification__active {
  from {
    background-color: #07bdf4;
  }
  to {
    background-color: #0388e5;
  }
}
</style>
