export default [
  {
    path: '/knowledge-base/',
    name: 'knowledge-base',
    component: () => import('@/view/knowledge/KnowledgeBase.vue'),
    props: true,
  },
  {
    path: '/knowledge-base/one-post/:postId',
    name: 'one-post',
    component: () => import('@/view/knowledge/OnePost.vue'),
    props: true,
  },
];
