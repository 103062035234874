import axios from 'axios';
import mutations from './mutations';
import apiLinks from '@/store/api.js';

const state = () => ({
  url: null,
  loading: true,
  error: null,
  message: null,
  dataAccount: {},
  data: [],
  pagination: [],
  logs: null,
  logsPagination: null,
  operations: null,
  operationsPagination: null,
  syncing: null,
  syncingPagination: null,
  graphData: null,
});

const actions = {
  async tradingAccountsGetData({ commit }, { queryLink, type, id }) {
    try {
      commit('axiosTradingReadBegin');
      const query = await axios.get(
        `${apiLinks[`${type ? 'trading_' + type : 'trade'}`]}${queryLink}${id ? '&user_id=' + id : ''}`,
      );
      await commit('axiosReadSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
  async tradingLogsGet({ commit }, { queryLink, accountId }) {
    try {
      commit('axiosTradingReadBegin');
      const query = await axios.get(
        `${apiLinks.api_logs}${queryLink}${accountId ? '&trade_account_id=' + accountId : ''}`,
      );
      await commit('axiosTradingLogsGetSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
  async tradingOperationsGet({ commit }, { queryLink, accountId }) {
    try {
      commit('axiosTradingReadBegin');
      const query = await axios.get(
        `${apiLinks.trade}${apiLinks.sync_data}${queryLink}${accountId ? '&trade_account_id=' + accountId : ''}`,
      );
      await commit('axiosTradingOperationsGetSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
  async tradingSyncingGet({ commit }, { queryLink, accountId }) {
    try {
      commit('axiosTradingReadBegin');
      const query = await axios.get(
        `${apiLinks.trade}${apiLinks.sync_infos}${queryLink}${accountId ? '&trade_account_id=' + accountId : ''}`,
      );
      await commit('axiosTradingSyncingGetSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
  async getTradingDataAccount({ commit }, id) {
    try {
      commit('axiosTradingReadBegin');
      const query = await axios.get(`${apiLinks.trade_accounts}${id}`);
      await commit('axiosReadAccSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
  async topUpTradingAccount({ commit }, { id, amount, comment }) {
    try {
      const query = await axios.post(`${apiLinks.trade_accounts}${id}/${apiLinks.to_pup}`, {
        amount: +amount,
        comment,
      });
      await commit('axiosAccountTopUp', query.data);
    } catch (err) {
      await commit('axiosAccountTopUp', err);
    }
  },
  async withdrawTradingAccount({ commit }, { id, amount, comment }) {
    try {
      const query = await axios.post(`${apiLinks.trade_accounts}${id}/${apiLinks.withdraw}`, {
        amount: +amount,
        comment,
      });
      await commit('axiosAccountTopUp', query.data);
    } catch (err) {
      await commit('axiosAccountTopUp', err);
    }
  },
  async getGraphTradingStats({ commit }, { id, group_by }) {
    try {
      commit('axiosTradingReadBegin');
      const query = await axios.get(
        `${apiLinks.trade_accounts}${id}/${apiLinks.graph}${group_by ? '?group_by=' + group_by : ''}`,
      );
      await commit('axiosAccountGraphStats', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },

  async changeStatusTradingAccount({ commit }, { acc_id, type }) {
    try {
      commit('axiosTradingReadBegin');
      await axios.patch(`${apiLinks.trade_accounts}${acc_id}/${apiLinks[type]}`);
      commit('axiosTradingReadSuccess');
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },

  async closeInvestAccount({ commit }, { acc_id }) {
    try {
      commit('axiosTradingReadBegin');
      await axios.patch(`${apiLinks.investor_accounts}${acc_id}/${apiLinks.close}`);
      commit('axiosTradingReadSuccess');
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
