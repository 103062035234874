import axios from 'axios';
import mutations from './mutations';
import apiLinks from '@/store/api.js';

const state = () => ({
  data: [],
  pagination: [],
  dataContest: null,
  membersData: [],
  membersMeta: [],
  loadingGift: false,
  loading: true,
  error: null,
  message: null,
});

const actions = {
  async lootBoxesGetData({ commit }, { queryLink, id }) {
    try {
      commit('lootBoxesBegin');
      const query = await axios.get(`${apiLinks.loot_boxes}${queryLink}${id ? '&user_id=' + id : ''}`);
      await commit('lootBoxesReadSuccess', query.data);
    } catch (err) {
      await commit('lootBoxErr', err);
    }
  },

  async giftLootBox({ commit }, { user_id }) {
    try {
      commit('lootBoxesBegin');
      const query = await axios.post(`${apiLinks.loot_boxes}`, { user_id });
      await commit('giftLootBoxSuccess', query.data);
    } catch (err) {
      await commit('lootBoxErr', err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
