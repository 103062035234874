export default [
  {
    path: '/users/',
    name: 'users',
    component: () => import('@/view/users/UsersCrud.vue'),
    props: true,
  },
  {
    path: '/user/:id/',
    name: 'user',
    children: [
      {
        path: ':changeType',
        name: 'changeUser',
        component: () => import('@/components/ModalComponents/ModalChange.vue'),
        props: true,
      },
    ],
    props: true,
    component: () => import('@/view/users/UserData.vue'),
  },
];
