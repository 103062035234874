export default {
  contestsReadBegin(state) {
    state.loading = true;
  },

  contestsReadSuccess(state, data) {
    state.data = data.data;
    state.pagination = data.meta.pagination;
    state.loading = false;
  },

  oneContestReadSuccess(state, data) {
    state.dataContest = data.data;

    state.loading = false;
  },

  membersReadSuccess(state, data) {
    state.membersData = data.data;
    state.membersMeta = data.meta.pagination;

    state.loading = false;
  },

  contestsSyncingGetSuccess(state, data) {
    state.syncing = data.data;
    state.syncingPagination = data.meta.pagination;
    state.loading = false;
  },

  endContestChange(state, data) {
    state.message = data.errors ?? null;
    state.loading = false;
  },

  contestsReadErr(state, err) {
    state.error = err;
    state.loading = false;
  },

  contestsAccountGraphStats(state, data) {
    state.graphData = data;
    state.loading = false;
  },
};
