const url = process.env.VUE_APP_API_URL + 'admin/';
const auth_url = process.env.VUE_APP_API_URL + 'auth/';

const notifications_url = process.env.VUE_APP_API_URL + 'notifications/';

const link_accounts = 'accounts/';
const link_accounts_types = 'account_types/';
const link_users = 'users/';
const link_profile = 'profile/';
const link_login = 'login/';
const link_refresh = 'refresh/';
const link_roles = 'roles/';
const link_request = 'requests/';
const link_withdrawals = 'request_withdrawals/';
const link_requests_transfers = 'request_transfers/';
const link_requests_exchanges = 'request_exchanges/';
const link_deposits = 'request_deposits/';
const link_logout = 'logout/';
const link_security = 'security/';
const link_sessions = 'sessions/';
const link_otp = 'otp/';
const link_audits = 'audits/';
const link_transactions = 'transactions/';
const link_wallets = 'user_wallets/';
const link_operations = 'operations/';
const link_payment_gates = 'payment_gates/';
const link_configs = 'configs/';
const link_stats = 'stats/';
const link_reports = 'reports/';
const link_main = 'main/';
const link_registrations = 'registrations/';
const link_admin_withdrawals = 'admin_withdrawals/';
const links_trade = 'trade/';
const links_api_logs = 'api_logs/';
const links_loot_boxes = 'loot_boxes/';
const links_purchases = 'purchases/';
const links_telegram_users = 'telegram_users/';
const links_published_accounts = 'published_accounts/';
const links_investor_accounts = 'investor_accounts/';
//endpoints

const password = 'password/';
const personal_data = 'personal_data/';
const personal_user_data = 'user_personal_data/';
const email = 'email/';
const approve = 'approve/';
const reject = 'reject/';
const delete_all = 'delete_all/';
const read = 'read/';
const settings = 'settings/';
const two_fac = 'two_fac/';
const to_pup = 'topup/';
const withdraw = 'withdraw/';
const disable_otp = 'disable_otp/';
const demo_accounts = 'demo_accounts/';
const real_accounts = 'real_accounts/';
const parent = 'parent/';
const sync_infos = 'sync_infos/';
const sync_data = 'sync_data/';
const graph = 'graph/';
const level = 'level/';
const subscription = 'subscription/';
const contests = 'contests/';
const members = '/members';
const contest_members = 'contest_members/';
const block = 'block/';
const unblock = 'unblock/';
const close = 'close/';
const unpublish = 'unpublish/';
//links

const accounts = url + link_accounts;
const account_types = url + link_accounts_types;
const users = url + link_users;
const profile = url + link_profile;
const stats = url + link_stats;
const roles = url + link_roles;
const wallets = url + link_wallets;
const withdrawals = url + link_request + link_withdrawals;
const deposits = url + link_request + link_deposits;
const transfers = url + link_request + link_requests_transfers;
const exchanges = url + link_request + link_requests_exchanges;
const admin_withdrawals = url + link_request + link_admin_withdrawals;
const transactions = url + link_accounts + link_transactions;
const payment_gates = url + link_operations + link_payment_gates;
const security = url + link_profile + link_security;
const sessions = url + link_security + link_sessions;
const audits = url + link_security + link_audits;
const login = auth_url + link_login;
const second_auth = auth_url + link_otp;
const refresh = auth_url + link_refresh;
const logout = auth_url + link_logout;
const security_configs = url + link_security + link_configs;
const trading_demo = url + links_trade + demo_accounts;
const trading_real = url + links_trade + real_accounts;
const trade = url + links_trade;
const trade_accounts = trade + link_accounts;
const api_logs = url + links_api_logs;
const loot_boxes = url + links_loot_boxes;
const published_accounts = trade + links_published_accounts;
const investor_accounts = trade + links_investor_accounts;
//reports
const usersDataReport = url + link_reports + link_users;
const withdrawalsReport = url + link_reports + link_withdrawals;
const mainReport = url + link_reports + link_main;
const depositsReport = url + link_reports + link_deposits;
const registrationsReport = url + link_reports + link_registrations;
const activeUserReport = url + link_reports + 'active/';
const requestDepositsReportLink = url + link_reports + 'request_deposits/';
const partnersReportLink = url + link_reports + 'partners/';

const purchases = url + links_purchases;
const telegram_users = url + links_telegram_users;

export default {
  notifications_url,
  password,
  personal_data,
  personal_user_data,
  email,
  approve,
  reject,
  delete_all,
  parent,
  read,
  settings,
  two_fac,
  to_pup,
  withdraw,
  disable_otp,
  accounts,
  account_types,
  users,
  profile,
  stats,
  roles,
  wallets,
  withdrawals,
  deposits,
  transfers,
  exchanges,
  transactions,
  payment_gates,
  security,
  sessions,
  link_sessions,
  unpublish,
  audits,
  login,
  second_auth,
  level,
  refresh,
  logout,
  security_configs,
  link_accounts,
  url,
  usersDataReport,
  withdrawalsReport,
  mainReport,
  depositsReport,
  registrationsReport,
  activeUserReport,
  requestDepositsReportLink,
  admin_withdrawals,
  trading_demo,
  trading_real,
  trade_accounts,
  contests,
  contest_members,
  api_logs,
  sync_infos,
  sync_data,
  trade,
  graph,
  block,
  unblock,
  close,
  members,
  loot_boxes,
  purchases,
  telegram_users,
  published_accounts,
  investor_accounts,
  subscription,
  partnersReportLink,
};
