import { createStore } from 'vuex';
import accounts from './modules/accounts/axios/actionCreator';
import users from './modules/users/axios/actionCreator';

import profile from './modules/profile/axios/actionCreator';
import notifications from './modules/notifications/axios/actionCreator';
import applications from './modules/applications/axios/actionCreator';
import partners from './modules/partners/axios/actionCreator';
import global from './modules/global/axios/actionCreator';
import tradingAccounts from './modules/trading-accounts/axios/actionCreator';
import publicAccounts from './modules/public-accounts/axios/actionCreator';
import security from './modules/security/axios/actionCreator';
import contests from './modules/contests/axios/actionCreator';
import lootBoxes from './modules/loot-boxes/axios/actionCreator';
import shop from './modules/shop/axios/actionCreator';
import telegramUsers from './modules/telegram-users/axios/actionCreator';
import { auth } from './auth.module';

export default createStore({
  modules: {
    auth,
    accounts,
    tradingAccounts,
    publicAccounts,
    security,
    profile,
    notifications,
    applications,
    partners,
    users,
    global,
    contests,
    lootBoxes,
    shop,
    telegramUsers,
  },
});
