export default [
  {
    path: '/start-page/',
    name: 'StartPage',
    component: () => import('@/view/global/Dashboard.vue'),
    props: true,
  },
  {
    path: '/system-settings/',
    name: 'systemSettings',
    component: () => import('@/view/global/SystemSettings.vue'),
    props: true,
  },
];
