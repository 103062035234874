import axios from 'axios';
import mutations from './mutations';
import apiLinks from '@/store/api.js';
/**/
const state = () => ({
  loading: true,
  dashboardStats: null,
  settings: null,
  reportLoading: false,
  error: false,
  counter: {
    withdrawals: 0,
    user_wallets: 0,
    transfers: 0,
  },
});

const actions = {
  async getAllDashboardStats({ commit }) {
    try {
      const query = await axios.get(apiLinks.stats);
      commit('getAllDashboardStatsSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
  async getReport({ commit }, { link, filterLink, label }) {
    try {
      commit('getReportBefore');
      if (filterLink) {
        //eslint-disable-next-line
        filterLink = filterLink.replace(/^\&/, '?');
      }
      const query = await axios.get(`${link}${filterLink}`, {
        responseType: 'blob',
      });
      const blob = new Blob([query.data], {
        type: query.headers['content-type'],
      });
      const element = document.createElement('a');
      element.href = URL.createObjectURL(blob);
      element.download = label;
      element.click();
      URL.revokeObjectURL(link.href);
      commit('getReportsSuccess');
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
  async getSettings({ commit }) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(apiLinks.url + apiLinks.settings);
      commit('getSettingsSuccess', query.data);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
