export default {
  axiosReadBegin(state) {
    state.loading = true;
  },

  axiosReadSuccess(state, data) {
    state.data = data.data;
    state.loading = false;
    state.error = false;
  },

  axiosReadErr(state, err) {
    state.error = err;
  },

  updateNotifications(state, data) {
    if (state.notifications) {
      state.notifications.unshift(data);
    }
    state.tosterNotifications.unshift(data);
    state.newNotifications++;
  },

  getNotificationsSuccess(state, data) {
    state.error = false;
    state.notifications = data.data;
    state.pagination = data.meta.pagination;
    state.loading = false;
  },

  getNotificationSuccess(state, data) {
    state.error = false;
    state.notification = data.data;
    state.loading = false;
  },

  getTosterNotificationsSuccess(state, data) {
    state.error = false;
    state.tosterNotifications = data.data;
    state.loading = false;
  },

  changeNewNotifications(state) {
    state.newNotifications++;
  },

  zeroNewNotifications(state) {
    state.newNotifications = 0;
  },

  axiosSendErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  createCustomNotificationSuccess(state, data) {
    state.customNotifications.push(data);
  },
};
