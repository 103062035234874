export default [
  {
    path: '/admin-account/security/',
    name: 'security',
    component: () => import('@/view/security/AdminSecurityCrud.vue'),
    props: true,
  },

  {
    path: '/security/sessions/',
    name: 'securitySessions',
    component: () => import('@/view/security/SecuritySessionsCrud.vue'),
    props: true,
  },
  {
    path: '/security/session/:id?',
    name: 'securitySession',
    component: () => import('@/view/security/SecuritySessionData.vue'),
    props: true,
  },
  {
    path: '/security/audits/',
    name: 'auditsSessions',
    component: () => import('@/view/audits/AuditsCrud.vue'),
    props: true,
  },
  {
    path: '/security/audit/:id?',
    name: 'auditSession',
    component: () => import('@/view/audits/AuditData.vue'),
    props: true,
  },
];
